
export enum ClubCategory {
    VLAAMS_BRABANT_BR = 2,
    BRUSSELS_BRABANT_WALLON = 3,
    ANTWERP = 4,
    OOST_VLANDEREN = 5,
    WEST_VLAANDEREN = 6,
    LIMBURG = 7,
    HAINAUT = 8,
    LUXEMBOURG = 9,
    LIEGE = 10,
    NAMUR = 11,
    VTTL = 12,
    AFTT = 13,
    FRBTT = 14,
}
