export enum PLAYER_CATEGORY {
    MEN = 'MEN',
    WOMEN = 'WOMEN',
    VETERANS = 'VETERANS',
    VETERANS_WOMEN = 'VETERANS_WOMEN',
    YOUTH = 'YOUTH',
}

export enum DIVISION_CATEGORY {

}

export type MEMBER_CATEGORY_STRING = 'MEN' | 'WOMEN' | 'VETERANS' | 'VETERANS_WOMEN' | 'YOUTH';
