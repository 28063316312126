export const firebaseConfig = {
    apiKey: 'AIzaSyDBSPwGZM0dZDDe3k2q3j3nET57TC1GC0Y',
    authDomain: 'beping-196714.firebaseapp.com',
    databaseURL: 'https://beping-196714.firebaseio.com',
    projectId: 'beping-196714',
    storageBucket: 'beping-196714.appspot.com',
    messagingSenderId: '169133988850',
    appId: '1:169133988850:web:f83e033f93de42ceb6696b',
    measurementId: 'G-NLCDC9F2GF'
};
